import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigAssetLoaderService } from '@core/services/config-asset-loader.service';
import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr';
import { SignalRNegotiateResult } from '@shared/models/signalrNegotiateResult';
import { WahlvorbereitungNotification } from '@shared/models/wahlvorbereitungInfo';
import { Observable, Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class SignalRService {
    private hubConnection: HubConnection;

    private WAHLVORBEREITUNG_MESSAGE: string = 'wahlvorbereitungMessage';

    private wahlvorbereitungNotificationSubject: Subject<WahlvorbereitungNotification> =
        new Subject<WahlvorbereitungNotification>();
    wahlvorbereitungNotification$ = this.wahlvorbereitungNotificationSubject.asObservable();

    constructor(private httpClient: HttpClient, private configService: ConfigAssetLoaderService) {}

    /**
     * Öffnet die Verbindung zum SignalR-Service.
     * Da dieser serverless betrieben wird, muss für den Verbindungsaufbau die "negotiate"-Azure-Function
     * aufgerufen werden.
     */
    startConnection(): void {
        // Verbindungsoptionen aus dem Backend laden
        this.getSignalRConnection().subscribe({
            next: (negotiateResult: SignalRNegotiateResult) => {
                if (negotiateResult) {
                    // Verbindung zum SignalRServer herstellen
                    this.establishHubConnection(negotiateResult);
                }
            },
            error: (error: HttpErrorResponse) => {
                // Fehlerhandling
                console.log('Verbindung mit dem SignalRService nicht möglich');
                console.log(JSON.stringify(error));
                return null;
            }
        });
    }

    /**
     * Stellt die Verbindung zum SignalRService und den Listener auf
     * Nachrichten für die Wahlvorbereitung her.
     * @param signalRConnection NegotiateResult
     */
    private establishHubConnection(signalRConnection: SignalRNegotiateResult): void {
        // Verbindung aufbauen
        this.hubConnection = new HubConnectionBuilder()
            .withUrl(signalRConnection.url, {
                // Der hier verwendete AccessToken kommt nicht vom IdentityProvider
                accessTokenFactory: () => signalRConnection.accessToken
            })
            .withAutomaticReconnect()
            .build();

        // Listener auf Nachrichten hier anlegen
        this.hubConnection.on(this.WAHLVORBEREITUNG_MESSAGE, (message: WahlvorbereitungNotification) => {
            this.wahlvorbereitungNotificationSubject.next(message);
        });

        // Verbindung starten
        this.hubConnection
            .start()
            .then(() => {
                console.log('Connection established');
            })
            .catch((err) => console.log('Error while starting connection: ' + err));
    }
    /**
     * Schließt eine bestehende Verbindung zum SignalR Hub
     */
    closeHubConnection(): void {
        if (this.hubConnection) {
            this.hubConnection.stop();
            this.hubConnection = null;
        }
    }

    /**
     * Liefert die Einstellungen für die Verbindung des UI Clients
     * zum Azure SignalR-Service zurück.
     * Aufrufer benötigt Rechte um Einstellungen zu erhalten
     * @returns NegotiateResult Einstellungen für die Verbindung zum SignalR-Service
     */
    private getSignalRConnection(): Observable<SignalRNegotiateResult> {
        return this.httpClient.get<SignalRNegotiateResult>(
            `${this.configService.getConfig().k5SignalR.signalrApiUrl}/negotiate`
        );
    }

    /**
     * Entfernt den angemeldeten Benutzer aus einer Nachrichtengruppe des SignalR-Service
     * damit keine Nachrichten für diese mehr angezeigt werden.
     * z.B.: Mandantenwechsel
     * @returns Observable Fehler oder OK
     */
    removeUserFromGroup(): Observable<any> {
        return this.httpClient.delete<any>(`${this.configService.getConfig().k5SignalR.signalrApiUrl}/negotiate`);
    }
}
